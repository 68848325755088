/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "gatsby-plugin-react-i18next";
import { useStaticQuery, graphql } from "gatsby";
import { useTranslation } from "react-i18next";

function SEO({ description, lang, meta, title }) {
  const { t } = useTranslation();
  const {
    site,
    socialThumbnailFeatureImg,
    socialFeatureImg,
    twitterFeatureImg,
  } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
          }
        }
        socialThumbnailFeatureImg: file(
          relativePath: { eq: "social_feature.png" }
        ) {
          childImageSharp {
            fluid(maxWidth: 180, maxHeight: 110, fit: INSIDE) {
              src
              presentationWidth
              presentationHeight
            }
          }
        }
        socialFeatureImg: file(relativePath: { eq: "social_feature.png" }) {
          childImageSharp {
            fluid(maxWidth: 1200, maxHeight: 630, fit: INSIDE) {
              src
              presentationWidth
              presentationHeight
            }
          }
        }
        twitterFeatureImg: file(relativePath: { eq: "social_feature.png" }) {
          childImageSharp {
            fluid(
              maxWidth: 1200
              maxHeight: 600
              fit: COVER
              cropFocus: CENTER
            ) {
              src
              presentationWidth
              presentationHeight
            }
          }
        }
      }
    `
  );

  const metaDescription = t("home.subheader");

  const socialTitle = `${title} | ${site.siteMetadata.title}`;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: socialTitle,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: "og:image",
          content: socialThumbnailFeatureImg.childImageSharp.fluid.src,
        },
        {
          property: "og:image:width",
          content:
            socialThumbnailFeatureImg.childImageSharp.fluid.presentationWidth,
        },
        {
          property: "og:image:height",
          content:
            socialThumbnailFeatureImg.childImageSharp.fluid.presentationHeight,
        },
        {
          property: "og:image",
          content: socialFeatureImg.childImageSharp.fluid.src,
        },
        {
          property: "og:image:width",
          content: socialFeatureImg.childImageSharp.fluid.presentationWidth,
        },
        {
          property: "og:image:height",
          content: socialFeatureImg.childImageSharp.fluid.presentationHeight,
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: "twitter:image",
          content: `${site.siteMetadata.siteUrl}${twitterFeatureImg.childImageSharp.fluid.src}`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: socialTitle,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
    />
  );
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
};

export default SEO;
