import React from "react";
import { graphql } from "gatsby";
import cx from "classnames";
import { StaticImage } from "gatsby-plugin-image";
import { useTranslation } from "gatsby-plugin-react-i18next";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import {
  APP_STORE_URL_ANDROID,
  APP_STORE_URL_IOS,
} from "../../constants/app.contants";
import * as styles from "./stores.module.scss";

const AppStoresPage = () => {
  const { t } = useTranslation();

  return (
    <Layout>
      <SEO title={t("app.stores.title")} />
      <div className={styles.stores}>
        <h1>{t("app.stores.title")}</h1>
        <div className={styles.storeTiles}>
          <a href={APP_STORE_URL_IOS} target="_blank" rel="noreferrer">
            <StaticImage
              src="./images/app-store.png"
              alt="Download on the App Store"
              height={44}
              loading="lazy"
              placeholder="tracedSVG"
              className={styles.storeTile}
            />
          </a>
          <a href={APP_STORE_URL_ANDROID} target="_blank" rel="noreferrer">
            <StaticImage
              src="./images/play-store.png"
              alt="Get it on Google Play"
              height={44}
              loading="lazy"
              placeholder="tracedSVG"
              className={cx(styles.storeTile, styles.right)}
            />
          </a>
        </div>
      </div>
    </Layout>
  );
};

export default AppStoresPage;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
